const yearsToFirstOrderOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i >= 2019; i--) {
    years.push({ label: i.toString(), value: i.toString() });
  }
  return years;
};
export const ONLINE_STORE_ATTRIBUTES = [
  {
    field: "state",
    title: "State",
    options: [
      { label: "Queensland", value: "Queensland" },
      { label: "Western Australia", value: "Western Australia" },
      { label: "South Australia", value: "South Australia" },
      { label: "New South Wales", value: "New South Wales" },
      { label: "Northern Territory", value: "Northern Territory" },
      {
        label: "Australian Capital Territory",
        value: "Australian Capital Territory"
      },
      { label: "Victoria", value: "Victoria" },
      { label: "Tasmania", value: "Tasmania" }
    ]
  },
  {
    field: "store",
    title: "Store",
    options: [
      { label: "Amazon", value: "amazon" },
      { label: "eBay", value: "ebay" },
      // { label: "Kogan", value: "kogan" },
      { label: "Harvey Norman", value: "harvey norman" },
      { label: "Catch", value: "catch" }
    ]
  },
  {
    field: "products",
    title: "Products",
    options: [
      { label: "M700", price: 3899.0, sku: "99996710-AU", value: "M700" },
      { label: "M600", price: 3599.0, sku: "99996610-AU", value: "M600" },
      { label: "X6", price: 3599.0, sku: "99996613-AU", value: "X6" },
      { label: "S400", price: 3349.0, sku: "99996261-AU", value: "S400" },
      {
        label: "LIBERTY 400",
        price: 2899.0,
        sku: "99998200-AU",
        value: "LIBERTY 400"
      },
      {
        label: "LIBERTY 400 BUNDLE (Caddy + Caddy Cover + Liberty organiser)",
        price: 3499.0,
        sku: "90-040-1006",
        value: "LIBERTY 400 BUNDLE (Caddy + Caddy Cover + Liberty organiser)"
      },
      {
        label: "M400 CB",
        price: 2749.0,
        sku: "99991049-AUI",
        value: "M400 CB"
      },
      {
        label: "M400 WB",
        price: 2749.0,
        sku: "99991048-AUI",
        value: "M400 WB"
      },
      { label: "X30", price: 2599.0, sku: "99996201-AU", value: "X30" },
      { label: "S200", price: 2499.0, sku: "99996202-AU", value: "S200" },
      {
        label: "S200 Bundle",
        price: 1999.0,
        sku: "90-040-1002",
        value: "S200 Bundle"
      },
      {
        label: "SWASH CL",
        price: 1718.44,
        sku: "99996044-AU",
        value: "SWASH CL"
      },
      { label: "S250", price: 2599.0, sku: "99996220-AUI", value: "S250" },
      { label: "S150", price: 1999.0, sku: "99996208-AUSWV", value: "S150" },
      { label: "S100", price: 1499.0, sku: "99996121-AU", value: "S100" },
      { label: "S50", price: 999.0, sku: "99996131-AUWT", value: "S50" },
      { label: "E10", price: 999.0, sku: "99996133-AU", value: "E10" },
      { label: "DB1", price: 999.0, sku: "99996114-DB1", value: "DB1" },
      {
        label: "EC HP 24w",
        price: 13558.55,
        sku: "10-440-1004",
        value: "EC HP 24w"
      },
      {
        label: "EC HP 20w",
        price: 11329.51,
        sku: "10-440-1003",
        value: "EC HP 20w"
      },
      {
        label: "EC HP 16w",
        price: 9004.67,
        sku: "10-440-1002",
        value: "EC HP 16w"
      },
      {
        label: "EC HP 12w",
        price: 7116.42,
        sku: "10-440-1001",
        value: "EC HP 12w"
      },
      {
        label: "EC VS 1500w",
        price: 3054.88,
        sku: "10-400-1005",
        value: "EC VS 1500w"
      },
      {
        label: "EC VS 800w",
        price: 2481.08,
        sku: "10-400-1004",
        value: "EC VS 800w"
      },
      {
        label: "EC 1500w",
        price: 1717.67,
        sku: "10-400-1003",
        value: "EC 1500w"
      },
      {
        label: "EC 1100w",
        price: 1544.28,
        sku: "10-400-1002",
        value: "EC 1100w"
      },
      {
        label: "EC 750w",
        price: 1414.55,
        sku: "10-400-1001",
        value: "EC 750w"
      },
      {
        label: "EC Media 28",
        price: 1532.14,
        sku: "10-420-1002",
        value: "EC Media 28"
      },
      {
        label: "EC Media 25",
        price: 1233.77,
        sku: "10-420-1001",
        value: "EC Media 25"
      },
      {
        label: "EC Cartridge 200",
        price: 1134.32,
        sku: "10-420-1005",
        value: "EC Cartridge 200"
      },
      {
        label: "EC Cartridge 150",
        price: 1051.23,
        sku: "10-420-1004",
        value: "EC Cartridge 150"
      },
      {
        label: "EC Cartridge 100",
        price: 969.39,
        sku: "10-420-1003",
        value: "EC Cartridge 100"
      },
      {
        label: "Dolphin Caddy (Swash & M Line)",
        price: 343.81,
        sku: "9996098-ASSY",
        value: "Dolphin Caddy (Swash & M Line)"
      },
      {
        label: "Dolphin Caddy (X Line & S Line)",
        price: 343.81,
        sku: "9996087-ASSY",
        value: "Dolphin Caddy (X Line & S Line)"
      },
      {
        label: "Universal Dolphin Caddy",
        price: 364.76,
        sku: "9996084-ASSY",
        value: "Universal Dolphin Caddy"
      },
      {
        label: "Dolphin Caddy Cover",
        price: 117.06,
        sku: "9991794",
        value: "Dolphin Caddy Cover"
      },
      {
        label: "M500 CB",
        price: 2883,
        sku: "99991089-AUI",
        value: "M500 CB"
      },
      {
        label: "M500 WB",
        price: 2883,
        sku: "99991088-AUI",
        value: "M500 WB"
      },
      {
        label: "SWASH CLX WB",
        price: 2062.36,
        sku: "99996048-SWX",
        value: "SWASH CLX WB"
      },
      {
        label: "SWASH CLX CB",
        price: 2062.36,
        sku: "99996049-SWX",
        value: "SWASH CLX CB"
      },
      {
        label: "SWASH CLX (Wonder Brush)",
        price: 999.0,
        sku: "99996048-SWX",
        value: "SWASH CLX (Wonder Brush)"
      },
      {
        label: "SWASH CLX (Combined Brush)",
        price: 999.0,
        sku: "99996049-SWX",
        value: "SWASH CLX (Combined Brush)"
      },
      {
        label: "SWASH TCX WB",
        price: 2728.41,
        sku: "99996045-AU-1",
        value: "SWASH TCX WB"
      },
      {
        label: "SWASH TCX CB",
        price: 2728.41,
        sku: "99996044-AU-1",
        value: "SWASH TCX CB"
      },
      {
        label: "SWASH TCX (Wonder Brush)",
        price: 1499.0,
        sku: "99996058-SWX",
        value: "SWASH TCX (Wonder Brush)"
      },
      {
        label: "SWASH TCX (Combined Brush)",
        price: 1499.0,
        sku: "99996059-SWX",
        value: "SWASH TCX (Combined Brush)"
      },
      {
        label: "ECLIPSE XLR WB",
        price: 2399.0,
        sku: "99996058-ECL",
        value: "ECLIPSE XLR WB"
      },
      {
        label: "ECLIPSE XL Wonder Brush",
        price: 1799.0,
        sku: "99996048-ECL",
        value: "ECLIPSE XL Wonder Brush"
      },
      {
        label: "ECLIPSE XL Combined Brush",
        price: 1799.0,
        sku: "99996049-ECL",
        value: "ECLIPSE XL Combined Brush"
      },
      {
        label: "Brush WB (CL,M4) (1pc)",
        price: 139.74,
        sku: "6101610",
        value: "Brush WB (CL,M4) (1pc)"
      },
      {
        label: "Brush WB (TC,M5,M400,M500,Liberty) (2pc)",
        price: 139.74,
        sku: "9995560-ASSY",
        value: "Brush WB (TC,M5,M400,M500,Liberty) (2pc)"
      },
      {
        label: "Brush PVC Single-Drive Grey (Swash,CL) (1pc)",
        price: 36.37,
        sku: "6101602",
        value: "Brush PVC Single-Drive Grey (Swash,CL) (1pc)"
      },
      {
        label: "Brush CB Single-Drive Grey (M3, M4) (1pc)",
        price: 36.37,
        sku: "6101641",
        value: "Brush CB Single-Drive Grey (M3, M4) (1pc)"
      },
      {
        label: "Brush WB Ring (All CB) (1pc)",
        price: 24.2,
        sku: "6101611",
        value: "Brush WB Ring (All CB) (1pc)"
      },
      {
        label: "Brush CB Dual-Drive Grey (M5,M400,M500) (1pc)",
        price: 23.5,
        sku: "6101656",
        value: "Brush CB Dual-Drive Grey (M5,M400,M500) (1pc)"
      },
      {
        label: "Cartridge Basket (E10,S50,BD1)",
        price: 206.53,
        sku: "9991460-ASSY",
        value: "Cartridge Basket (E10,S50,BD1)"
      },
      {
        label: "Ultra-Fine Filter Panels 4PK (CLX, TCX, XL, XLR)",
        price: 139.74,
        sku: "9991425-ASSY",
        value: "Ultra-Fine Filter Panels 4PK (CLX, TCX, XL, XLR)"
      },
      {
        label: "Filter Cartridge Kit - Ultra Fine (M-Series)",
        price: 159.29,
        sku: "9991432-ASSY",
        value: "Filter Cartridge Kit - Ultra Fine (M-Series)"
      },
      {
        label: "Filter Cartridge Kit - Ultrafine (S300i/X40+)",
        price: 159.29,
        sku: "9991467-ASSY",
        value: "Filter Cartridge Kit - Ultrafine (S300i/X40+)"
      },
      {
        label: "Cartridge Net Filter Kit (M400,M500)",
        price: 123.88,
        sku: "9991433-ASSY",
        value: "Cartridge Net Filter Kit (M400,M500)"
      },
      {
        label: "Cartridge Net Filter Kit (X6,S300i,M600)",
        price: 123.88,
        sku: "9991463-ASSY",
        value: "Cartridge Net Filter Kit (X6,S300i,M600)"
      },
      {
        label: "Filter Basket Rough - Net (S300i/X40+)",
        price: 127.6,
        sku: "9983106",
        value: "Filter Basket Rough - Net (S300i/X40+)"
      },
      {
        label: "Filter Canister Monoblock (E10/S50/DB1)",
        price: 115.41,
        sku: "9991461-ASSY",
        value: "Filter Canister Monoblock (E10/S50/DB1)"
      },
      {
        label: "Filter Bag – Fine (M3,CL,TC)",
        price: 99.61,
        sku: "99954307-ASSY",
        value: "Filter Bag – Fine (M3,CL,TC)"
      },
      {
        label: "Power Supply (X40+,S200,S300i,DB1,DB2)",
        price: 370.82,
        sku: "99956033-AU-15",
        value: "Power Supply (X40+,S200,S300i,DB1,DB2)"
      },
      {
        label: "Power Supply (M500)",
        price: 310.02,
        sku: "9995679-ASSY",
        value: "Power Supply (M500)"
      },
      {
        label: "Charger for Liberty (Liberty)",
        price: 297.85,
        sku: "9995680-AU-15",
        value: "Charger for Liberty (Liberty)"
      },
      {
        label: "Power Supply (E10,X30,S50)",
        price: 273.53,
        sku: "99956032-AU-15",
        value: "Power Supply (E10,X30,S50)"
      },
      {
        label: "Power Supply (CL,M3,M4,M400)",
        price: 237.05,
        sku: "9995670-AU-15",
        value: "Power Supply (CL,M3,M4,M400)"
      },
      {
        label: "Power Cable (ALL)",
        price: 30.3,
        sku: "58984405LF",
        value: "Power Cable (ALL)"
      }
    ]
  },
  {
    field: "freight",
    title: "Freight",
    options: [
      {
        label: "Free shipping over $999",
        price: 0,
        sku: "FR",
        value: "Free shipping over $999"
      },
      {
        label: "eBay Flat Rate",
        price: 9.9,
        sku: "FR",
        value: "eBay Flat Rate"
      },
      {
        label: "Amazon Flat Rate",
        price: 27.5,
        sku: "FR",
        value: "Amazon Flat Rate"
      },
      // {
      //   label: "Kogan Flat Rate",
      //   price: 9.9,
      //   sku: "FR",
      //   value: "Kogan Flat Rate"
      // },
      {
        label: "Harvey Norman Flat Rate",
        price: 9.9,
        sku: "FR",
        value: "Harvey Norman Flat Rate"
      },
      {
        label: "Catch Flat Rate",
        price: 9.9,
        sku: "FR",
        value: "Catch Flat Rate"
      }
    ]
  },
  {
    field: "status",
    title: "Status",
    options: [
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" }
    ]
  }
];

export const ONLINE_STORE_FILTERS = [
  {
    field: "store",
    title: "Store",
    options: [
      { label: "Amazon", value: "amazon" },
      { label: "eBay", value: "ebay" },
      // { label: "Kogan", value: "kogan" },
      { label: "Harvey Norman", value: "harvey norman" },
      { label: "Catch", value: "catch" }
    ]
  },
  {
    field: "state",
    title: "State",
    options: [
      { label: "Queensland", value: "Queensland" },
      { label: "Western Australia", value: "Western Australia" },
      { label: "South Australia", value: "South Australia" },
      { label: "New South Wales", value: "New South Wales" },
      { label: "Northern Territory", value: "Northern Territory" },
      {
        label: "Australian Capital Territory",
        value: "Australian Capital Territory"
      },
      { label: "Victoria", value: "Victoria" },
      { label: "Tasmania", value: "Tasmania" }
    ]
  },
  {
    field: "products",
    title: "Products",
    options: [
      { label: "M700", price: 3899.0, sku: "99996710-AU", value: "M700" },
      { label: "M600", price: 3599.0, sku: "99996610-AU", value: "M600" },
      { label: "X6", price: 3599.0, sku: "99996613-AU", value: "X6" },
      { label: "S400", price: 3349.0, sku: "99996261-AU", value: "S400" },
      {
        label: "LIBERTY 400",
        price: 2899.0,
        sku: "99998200-AU",
        value: "LIBERTY 400"
      },
      {
        label: "LIBERTY 400 BUNDLE (Caddy + Caddy Cover + Liberty organiser)",
        price: 3499.0,
        sku: "90-040-1006",
        value: "LIBERTY 400 BUNDLE (Caddy + Caddy Cover + Liberty organiser)"
      },
      {
        label: "M400 CB",
        price: 2749.0,
        sku: "99991049-AUI",
        value: "M400 CB"
      },
      {
        label: "M400 WB",
        price: 2749.0,
        sku: "99991048-AUI",
        value: "M400 WB"
      },
      { label: "X30", price: 2599.0, sku: "99996201-AU", value: "X30" },
      { label: "S200", price: 2499.0, sku: "99996202-AU", value: "S200" },
      {
        label: "S200 Bundle",
        price: 1999.0,
        sku: "90-040-1002",
        value: "S200 Bundle"
      },
      {
        label: "SWASH CL",
        price: 1718.44,
        sku: "99996044-AU",
        value: "SWASH CL"
      },
      { label: "S250", price: 2599.0, sku: "99996220-AUI", value: "S250" },
      { label: "S150", price: 1999.0, sku: "99996208-AUSWV", value: "S150" },
      { label: "S100", price: 1499.0, sku: "99996121-AU", value: "S100" },
      { label: "S50", price: 999.0, sku: "99996131-AUWT", value: "S50" },
      { label: "E10", price: 999.0, sku: "99996133-AU", value: "E10" },
      { label: "DB1", price: 999.0, sku: "99996114-DB1", value: "DB1" },
      {
        label: "EC HP 24w",
        price: 13558.55,
        sku: "10-440-1004",
        value: "EC HP 24w"
      },
      {
        label: "EC HP 20w",
        price: 11329.51,
        sku: "10-440-1003",
        value: "EC HP 20w"
      },
      {
        label: "EC HP 16w",
        price: 9004.67,
        sku: "10-440-1002",
        value: "EC HP 16w"
      },
      {
        label: "EC HP 12w",
        price: 7116.42,
        sku: "10-440-1001",
        value: "EC HP 12w"
      },
      {
        label: "EC VS 1500w",
        price: 3054.88,
        sku: "10-400-1005",
        value: "EC VS 1500w"
      },
      {
        label: "EC VS 800w",
        price: 2481.08,
        sku: "10-400-1004",
        value: "EC VS 800w"
      },
      {
        label: "EC 1500w",
        price: 1717.67,
        sku: "10-400-1003",
        value: "EC 1500w"
      },
      {
        label: "EC 1100w",
        price: 1544.28,
        sku: "10-400-1002",
        value: "EC 1100w"
      },
      {
        label: "EC 750w",
        price: 1414.55,
        sku: "10-400-1001",
        value: "EC 750w"
      },
      {
        label: "EC Media 28",
        price: 1532.14,
        sku: "10-420-1002",
        value: "EC Media 28"
      },
      {
        label: "EC Media 25",
        price: 1233.77,
        sku: "10-420-1001",
        value: "EC Media 25"
      },
      {
        label: "EC Cartridge 200",
        price: 1134.32,
        sku: "10-420-1005",
        value: "EC Cartridge 200"
      },
      {
        label: "EC Cartridge 150",
        price: 1051.23,
        sku: "10-420-1004",
        value: "EC Cartridge 150"
      },
      {
        label: "EC Cartridge 100",
        price: 969.39,
        sku: "10-420-1003",
        value: "EC Cartridge 100"
      },
      {
        label: "Dolphin Caddy (Swash & M Line)",
        price: 343.81,
        sku: "9996098-ASSY",
        value: "Dolphin Caddy (Swash & M Line)"
      },
      {
        label: "Dolphin Caddy (X Line & S Line)",
        price: 343.81,
        sku: "9996087-ASSY",
        value: "Dolphin Caddy (X Line & S Line)"
      },
      {
        label: "Universal Dolphin Caddy",
        price: 364.76,
        sku: "9996084-ASSY",
        value: "Universal Dolphin Caddy"
      },
      {
        label: "Dolphin Caddy Cover",
        price: 117.06,
        sku: "9991794",
        value: "Dolphin Caddy Cover"
      },
      {
        label: "M500 CB",
        price: 2883,
        sku: "99991089-AUI",
        value: "M500 CB"
      },
      {
        label: "M500 WB",
        price: 2883,
        sku: "99991088-AUI",
        value: "M500 WB"
      },
      {
        label: "SWASH CLX WB",
        price: 2062.36,
        sku: "99996048-SWX",
        value: "SWASH CLX WB"
      },
      {
        label: "SWASH CLX CB",
        price: 2062.36,
        sku: "99996049-SWX",
        value: "SWASH CLX CB"
      },
      {
        label: "SWASH CLX (Wonder Brush)",
        price: 999.0,
        sku: "99996048-SWX",
        value: "SWASH CLX (Wonder Brush)"
      },
      {
        label: "SWASH CLX (Combined Brush)",
        price: 999.0,
        sku: "99996049-SWX",
        value: "SWASH CLX (Combined Brush)"
      },
      {
        label: "SWASH TCX WB",
        price: 2728.41,
        sku: "99996045-AU-1",
        value: "SWASH TCX WB"
      },
      {
        label: "SWASH TCX CB",
        price: 2728.41,
        sku: "99996044-AU-1",
        value: "SWASH TCX CB"
      },
      {
        label: "SWASH TCX (Wonder Brush)",
        price: 1499.0,
        sku: "99996058-SWX",
        value: "SWASH TCX (Wonder Brush)"
      },
      {
        label: "SWASH TCX (Combined Brush)",
        price: 1499.0,
        sku: "99996059-SWX",
        value: "SWASH TCX (Combined Brush)"
      },
      {
        label: "ECLIPSE XLR WB",
        price: 2399.0,
        sku: "99996058-ECL",
        value: "ECLIPSE XLR WB"
      },
      {
        label: "ECLIPSE XL Wonder Brush",
        price: 1799.0,
        sku: "99996048-ECL",
        value: "ECLIPSE XL Wonder Brush"
      },
      {
        label: "ECLIPSE XL Combined Brush",
        price: 1799.0,
        sku: "99996049-ECL",
        value: "ECLIPSE XL Combined Brush"
      },
      {
        label: "Brush WB (CL,M4) (1pc)",
        price: 139.74,
        sku: "6101610",
        value: "Brush WB (CL,M4) (1pc)"
      },
      {
        label: "Brush WB (TC,M5,M400,M500,Liberty) (2pc)",
        price: 139.74,
        sku: "9995560-ASSY",
        value: "Brush WB (TC,M5,M400,M500,Liberty) (2pc)"
      },
      {
        label: "Brush PVC Single-Drive Grey (Swash,CL) (1pc)",
        price: 36.37,
        sku: "6101602",
        value: "Brush PVC Single-Drive Grey (Swash,CL) (1pc)"
      },
      {
        label: "Brush CB Single-Drive Grey (M3, M4) (1pc)",
        price: 36.37,
        sku: "6101641",
        value: "Brush CB Single-Drive Grey (M3, M4) (1pc)"
      },
      {
        label: "Brush WB Ring (All CB) (1pc)",
        price: 24.2,
        sku: "6101611",
        value: "Brush WB Ring (All CB) (1pc)"
      },
      {
        label: "Brush CB Dual-Drive Grey (M5,M400,M500) (1pc)",
        price: 23.5,
        sku: "6101656",
        value: "Brush CB Dual-Drive Grey (M5,M400,M500) (1pc)"
      },
      {
        label: "Cartridge Basket (E10,S50,BD1)",
        price: 206.53,
        sku: "9991460-ASSY",
        value: "Cartridge Basket (E10,S50,BD1)"
      },
      {
        label: "Ultra-Fine Filter Panels 4PK (CLX, TCX, XL, XLR)",
        price: 139.74,
        sku: "9991425-ASSY",
        value: "Ultra-Fine Filter Panels 4PK (CLX, TCX, XL, XLR)"
      },
      {
        label: "Filter Cartridge Kit - Ultra Fine (M-Series)",
        price: 159.29,
        sku: "9991432-ASSY",
        value: "Filter Cartridge Kit - Ultra Fine (M-Series)"
      },
      {
        label: "Filter Cartridge Kit - Ultrafine (S300i/X40+)",
        price: 159.29,
        sku: "9991467-ASSY",
        value: "Filter Cartridge Kit - Ultrafine (S300i/X40+)"
      },
      {
        label: "Cartridge Net Filter Kit (M400,M500)",
        price: 123.88,
        sku: "9991433-ASSY",
        value: "Cartridge Net Filter Kit (M400,M500)"
      },
      {
        label: "Cartridge Net Filter Kit (X6,S300i,M600)",
        price: 123.88,
        sku: "9991463-ASSY",
        value: "Cartridge Net Filter Kit (X6,S300i,M600)"
      },
      {
        label: "Filter Basket Rough - Net (S300i/X40+)",
        price: 127.6,
        sku: "9983106",
        value: "Filter Basket Rough - Net (S300i/X40+)"
      },
      {
        label: "Filter Canister Monoblock (E10/S50/DB1)",
        price: 115.41,
        sku: "9991461-ASSY",
        value: "Filter Canister Monoblock (E10/S50/DB1)"
      },
      {
        label: "Filter Bag – Fine (M3,CL,TC)",
        price: 99.61,
        sku: "99954307-ASSY",
        value: "Filter Bag – Fine (M3,CL,TC)"
      },
      {
        label: "Power Supply (X40+,S200,S300i,DB1,DB2)",
        price: 370.82,
        sku: "99956033-AU-15",
        value: "Power Supply (X40+,S200,S300i,DB1,DB2)"
      },
      {
        label: "Power Supply (M500)",
        price: 310.02,
        sku: "9995679-ASSY",
        value: "Power Supply (M500)"
      },
      {
        label: "Charger for Liberty (Liberty)",
        price: 297.85,
        sku: "9995680-AU-15",
        value: "Charger for Liberty (Liberty)"
      },
      {
        label: "Power Supply (E10,X30,S50)",
        price: 273.53,
        sku: "99956032-AU-15",
        value: "Power Supply (E10,X30,S50)"
      },
      {
        label: "Power Supply (CL,M3,M4,M400)",
        price: 237.05,
        sku: "9995670-AU-15",
        value: "Power Supply (CL,M3,M4,M400)"
      },
      {
        label: "Power Cable (ALL)",
        price: 30.3,
        sku: "58984405LF",
        value: "Power Cable (ALL)"
      }
    ]
  },
  {
    field: "freight",
    title: "Freight",
    options: [
      {
        label: "Free shipping over $850",
        price: 0,
        sku: "FR",
        value: "Free shipping over $850"
      },
      {
        label: "Free shipping over $999",
        price: 0,
        sku: "FR",
        value: "Free shipping over $999"
      },
      {
        label: "eBay Flat Rate",
        price: 9.9,
        sku: "FR",
        value: "eBay Flat Rate"
      },
      {
        label: "Amazon Flat Rate",
        price: 27.5,
        sku: "FR",
        value: "Amazon Flat Rate"
      },
      // {
      //   label: "Kogan Flat Rate",
      //   price: 9.9,
      //   sku: "FR",
      //   value: "Kogan Flat Rate"
      // },
      {
        label: "Harvey Norman Flat Rate",
        price: 9.9,
        sku: "FR",
        value: "Harvey Norman Flat Rate"
      },
      {
        label: "Catch Flat Rate",
        price: 9.9,
        sku: "FR",
        value: "Catch Flat Rate"
      }
    ]
  },
  {
    field: "user",
    title: "User",
    options: [
      { label: "Jane", value: "jane.estudillo@maytronics.com" },
      { label: "Vanessa", value: "vanessa.devaux@maytronics.com" },
      { label: "Kaira", value: "kaira.lasig@maytronics.com" },
      { label: "David", value: "david.dean@maytronics.com" },
      { label: "Wilson", value: "wilson.esmundo@maytronics.com" },
      { label: "Jezreel", value: "jezreel.escarez@maytronics.com" }
    ]
  },
  {
    field: "tracking",
    title: "Tracking",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false }
    ]
  },
  {
    field: "date",
    title: "Year",
    options: yearsToFirstOrderOptions()
  },
  {
    field: "month",
    title: "Month",
    options: [
      { label: "Jan", value: "01" },
      { label: "Feb", value: "02" },
      { label: "Mar", value: "03" },
      { label: "Apr", value: "04" },
      { label: "May", value: "05" },
      { label: "Jun", value: "06" },
      { label: "Jul", value: "07" },
      { label: "Aug", value: "08" },
      { label: "Sep", value: "09" },
      { label: "Oct", value: "10" },
      { label: "Nov", value: "11" },
      { label: "Dec", value: "12" }
    ]
  },
  {
    field: "status",
    title: "Status",
    options: [
      { label: "Processing", value: "processing" },
      { label: "Delivered", value: "delivered" },
      { label: "Cancelled", value: "cancelled" },
      { label: "Refunded", value: "refunded" },
      { label: "Returned", value: "returned" }
    ]
  }
];
